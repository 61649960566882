import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

//Sections
const Landing = () => import('@/pages/web/Landing');
const Tickets = () => import('@/pages/web/Tickets');
const Payments = () => import('@/pages/web/Payments');
const LandingTerms = () => import('@/pages/web/LandingTerms')
const Login = () => import('@/pages/application/login/Login')
const NewAccountForm = () => import('@/pages/application/login/NewAccountForm')
const ActivationForm = () => import('@/pages/application/login/ActivationForm')
const ForgetForm = () => import('@/pages/application/login/ForgetForm')
const RecoveryForm = () => import('@/pages/application/login/RecoveryForm')

const Users = () => import('@/pages/application/Users')
const Roles = () => import('@/pages/application/Roles')
const Raffles = () => import('@/pages/administrator/Raffles')
const Gamblers = () => import('@/pages/administrator/Gamblers')
const Reservations = () => import('@/pages/administrator/Reservations')

//Users
const MyAccount = () => import('@/pages/application/configuration/MyAccount')

// Views
const Home = () => import('@/pages/application/Home')
Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/control',
      redirect: 'control/raffles',
      name: 'Sorteos',
      component: TheContainer,
      children: [
        {
          path: '/control/users',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/control/roles',
          name: 'Roles',
          component: Roles
        },
        {
          path: '/control/raffles',
          name: 'Sorteos',
          component: Raffles
        },
        {
          path: '/control/:code/gamblers',
          name: 'Participantes',
          component: Gamblers
        },
        {
          path: '/control/:code/reservations',
          name: 'Reservaciones',
          component: Reservations
        },
        {
          path: '/control/my-account',
          name: 'Mi Cuenta',
          component: MyAccount
        },
      ]
    },
    {
      path: '/',
      name: 'Welcome',
      component: {        
        render (c) { return c('router-view') },
      },
      meta: {
        is_public: true,
      },
      children: [
        {
          path: '/',
          name: 'Landing Page',
          component: Landing,
          meta: {
            is_public: true,
          },
        },
        {
          path: '/boletos',
          name: 'Página de Boletos',
          component: Tickets,
          meta: {
            is_public: true,
          },
        },
        {
          path: '/pagos',
          name: 'Página de Pagos',
          component: Payments,
          meta: {
            is_public: true,
          },
        },
        {
          path: '/aviso-de-privacidad',
          name: 'Aviso de Privacidad',
          component: LandingTerms,
          meta: {
            is_public: true,
          },
        },
        {
          path: '/:code',
          name: 'Landing Page',
          component: Landing,
          meta: {
            is_public: true,
          },
        },
        {
          path: '/:code/pagos',
          name: 'Página de Pagos',
          component: Payments,
          meta: {
            is_public: true,
          },
        },
        {
          path: '/:code/boletos',
          name: 'Página de Boletos',
          component: Tickets,
          meta: {
            is_public: true,
          },
        },
        {
          path: '/:code/aviso-de-privacidad',
          name: 'Aviso de Privacidad',
          component: LandingTerms,
          meta: {
            is_public: true,
          },
        },
      ]
    },
    {
      path: '/signup',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/account/active/:id/:code',
      name: 'Activa tu Cuenta',
      meta: {
        is_public: true,
      },
      component: ActivationForm
    },
    {
      path: '/forget',
      name: '¿Olvidaste tu Contraseña?',
      meta: {
        is_public: true,
      },
      component: ForgetForm
    },
    {
      path: '/account/recover-password/:id/:code',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: RecoveryForm
    },
  ]
})